<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12" class="justify-center">

        <h2 class="toolbar--text text-center">{{ $t('Hi, Really Good Emails, welcome to BlockChainMetrics') }}</h2>
        <h3 class="toolbar--text text-center">
          {{ message }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, commit, call } from 'vuex-pathify'

export default {
  name:'ConfirmSubscription',
  data() {
    return {
      message: '',
    }
  },
  async mounted() {
    this.postConfirmSubscription()
  },
  methods: {
    ...call('account', ['CONFIRMSUBSCRIPTION']),
    async postConfirmSubscription() {
      const payload = {
        email: this.$route.query.email,
        confirmCode: this.$route.query.code,
      }

      const response = await this.CONFIRMSUBSCRIPTION(payload)

      if (response && response.status === 200) {
        this.message = response.data.detail.msg
      }
    },
  },
}
</script>
